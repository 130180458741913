<template>
    <section>
        <div class="row mx-0 justify-content-end bg-white br-t-12 px-3 py-3">
            <div class="col-auto pr-3">
                <el-date-picker
                v-model="fecha"
                type="date"
                size="small"
                placeholder="Fecha"
                value-format="yyyy-MM-dd"
                format="dd MMM yyyy"
                :clearable="false"
                :picker-options="pickerOptions"
                />
            </div>
            <div class="col-auto">
                <el-time-picker
                v-model="horas"
                is-range
                size="small"
                value-format="HH:mm"
                format="hh:mm a"
                range-separator=""
                start-placeholder="Hora inicio"
                end-placeholder="Hora fin"
                :clearable="false"
                />
            </div>
            <button class="btn-general px-3" @click="generar()">
                Generar ubicaciones
            </button>
        </div>
        <section class="custom-scroll overflow-auto" style="height:calc(100vh - 249px);">
            <mapa-google
            ref="GoogleMaps"
            :desactivar-mapa="false"
            icono-central="/img/icons/tendero-point-map.svg"
            :dragable="false"
            height="100%"
            width="100%"
            />
        </section>
    </section>
</template>

<script>
import moment from 'moment'
import ubicacion from '~/services/delivery/ubicacion'

export default {
    data(){
        return {
            fecha: '',
            horas: '',
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() >= new Date()
                }
            },
            puntos: []
        }
    },
    mounted(){
        this.iniciarMapa()
    },
    methods: {
        async iniciarMapa(){
            this.$refs.GoogleMaps.initMapMarkerClusterer(this.puntos, true, true)
        },
        addNewMarkers(){
            this.$refs.GoogleMaps.addNewMarkers(this.puntos, true, true)
        },
        async generar(){
            try {
                if ( !this.fecha || !this.horas ){
                    return this.notificacion('', 'Debe seleccionar todos los filtros', 'error')
                }
                let params = {
                    delivery: this.$route.params.id_delivery,
                    fecha: this.fecha,
                    horas: this.horas.toString()
                }
                const { data } = await ubicacion.puntos(params);

                const contentString = (el) => {
                    let fecha = moment(el.created_at).format('hh:mm a')
                    return `
                        <div class="row mx-0 my-2">
                            <span class="f-14">${fecha}</span>
                        </div>
                    `;        
                }
                let puntos = data.puntos.map(e => ({
                    lat: +(e.latitud),
                    lng: +(e.longitud),
                    icon: '/img/icons/delivery_ubicacion.svg',
                    info: contentString(e)
                }))
                this.puntos = puntos
                this.addNewMarkers()
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style>

</style>